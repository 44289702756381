var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-ios",
  body: function iconiOS() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 13 16\">" + "<g>" + "<path d=\"M10.858,8.488 C10.885,10.907 12.980,11.710 13.005,11.718 C12.987,11.774 12.673,12.867 11.901,13.994 C11.239,14.970 10.550,15.940 9.465,15.962 C8.395,15.984 8.048,15.330 6.827,15.330 C5.601,15.334 5.221,15.949 4.212,15.988 C3.160,16.027 2.358,14.939 1.688,13.968 C0.314,11.987 -0.737,8.363 0.665,5.912 C1.363,4.699 2.610,3.927 3.965,3.906 C4.994,3.884 5.970,4.599 6.602,4.599 C7.233,4.594 8.412,3.736 9.659,3.862 C10.178,3.884 11.636,4.070 12.581,5.440 C12.504,5.488 10.836,6.463 10.858,8.488 ZM6.480,3.684 C6.358,2.765 6.814,1.807 7.329,1.209 C7.906,0.532 8.878,0.029 9.680,-0.000 C9.785,0.935 9.408,1.876 8.848,2.552 C8.290,3.225 7.377,3.753 6.480,3.684 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;